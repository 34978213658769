.button {
	border: 1px solid get-color('blue');
	color: get-color('blue');
	font-size: 11px;
	padding: 10px 20px;
	text-transform: uppercase;
	background-color: transparent;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
	font-weight: 600;
	letter-spacing: 0.5px;
	font-family: $secondary-font;

	&:hover,
	&:active {
		background-color: get-color('blue');
		color: get-color('white');
	}

	&--primary {
		border-color: get-color('blue');
		background-color: get-color('white');
		color: get-color('blue');

		&:hover,
		&:active {
			border-color: get-color('gold');
			background-color: get-color('gold');
			color: get-color('white');
		}
	}
}

.multiple-buttons {
	text-align: left;
	width: calc(100% + 24px);
	margin-left: -12px;
	margin-right: -12px;

	.button {
		margin: 0 12px 12px;
		
		@include breakpoint(small only) {
			margin-left: 7px;
			margin-right: 7px;
			
			&:first-child {
				margin-left: 0;
			}
			
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
