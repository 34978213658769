.other-rooms {
	text-align: center;
	width: 100%;
	max-width: 1100px;
	margin: 0 auto 60px;
	padding-top: 141px;
	background: url('img/separator-dark.svg') no-repeat center -50px;
	
	@include breakpoint(small only) {
		background-image: url('img/separator-dark-small.svg');
		background-position: center top;
	}

	@media screen and (max-width: 1150px) {
		padding: 110px 20px 0;
	}

	h2 {
		@include h2;
		margin-bottom: 60px;
		text-transform: uppercase;
	}

	&__room {
		padding: 0 25px 0;
		transition: all 0.3s ease-in-out;
	}

	&__image {
		margin-bottom: 0;
	}

	&__content {
		padding: 33px 4% 15px;
		background-color: get-color('white');
		//border: 1px solid get-color('light-grey');

		@include breakpoint(small only) {
			border: none;
			padding: 33px 4% 0px;
		}
		h3 {
			@include h2;
			margin-bottom: 24px;
			text-transform: uppercase;

			a {
				color: inherit;
			}
		}

		.multiple-buttons {
			margin: 0;
			text-align: center;
			width: auto;

			.button {
				margin: 0 5px 12px;

				@include breakpoint(small only){
					margin: 0 2px 12px;
				}
			}
		}
	}

	&__rate {
		@include price;
		margin-bottom: 37px;

	}

	.slick-center {
		.other-rooms__content {
			padding: 33px 4% 40px;
		}
	}


	.slick-arrow {
		position: absolute;
		z-index: 100;
		width: 21px;
		height: 21px;
		transition: all 0.3s ease-in-out;
		cursor: pointer;
		text-indent: -12345px;
		background: no-repeat;
		background-size: 27px 21px;
		margin-top: -10px;

		&:hover,
		&:active {
			width: 27px;
		}

		&.slick-prev {
			left: -15px;
			background-image: url('img/carousel-prev.svg');
			background-position: left center;
		}

		&.slick-next {
			right: -15px;
			background-image: url('img/carousel-next.svg');
			background-position: right center;
		}
	}


	&__carousel.one-room {
		max-width: 400px;
		margin: auto;
	}

	&__carousel.two-rooms {
		max-width: 750px;
		margin: auto;
	}

	&__carousel.center-mode {
		.other-rooms {
			&__room {
				@include breakpoint(large) {
					padding-top: 75px;
				}
			}

			&__content .multiple-buttons {
				@include breakpoint(large) {
					display: none;
				}
			}

			&__rate {
				@include breakpoint(large) {
					display: none;
				}
			}
		}

		.slick-current .other-rooms {
			&__room {
				@include breakpoint(large) {
					padding-top: 0;
				}
			}

			&__content .multiple-buttons {
				@include breakpoint(large) {
					display: block;
				}
			}

			&__rate {
				@include breakpoint(large) {
					display: block;
				}
			}
		}
	}
}
