.offers {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto 120px;

	@include breakpoint(small only) {
		margin-bottom: 0px;
	}

	&__grid {
		@include breakpoint(small only) {
			padding: 0 20px;
		}
	}

	&__item {
		margin-bottom: 100px;
		min-height: 330px;
		height: 100%;

		@include breakpoint(small only) {
			margin-bottom: 60px;
		}

		&:nth-of-type(odd) {

			.offers__item-content {
				background-color: #F0F8FC;
			}
		}

		&-content {
			padding: 30px 20px;

			@include breakpoint(small only){
				border: none;
			}

			@include breakpoint(large) {
				padding: 50px 40px;
			}

			h2 {
				@include h2;
				text-transform: uppercase;
				margin-bottom: 30px;
				padding-bottom: 0;

				a {
					color: inherit;
				}
			}

			h3 {
				@include price;
				color: get-color('blue');
				margin-bottom: 30px;
			}

			p {
				@include body-copy();
			}

			ul {
				@include bullet-list();
			}
		}

		&-description {
			@include breakpoint(medium) {
				padding-right: 40px;
			}
		}

		&-price {
			@include breakpoint(small only) {
				padding-bottom: 15px;
			}

			@include breakpoint(medium) {
				padding-top: 10px;
				text-align: right;
			}
		}

		.multiple-buttons {
			@include breakpoint(small only) {
				margin-left: 0;

				.button {
					margin: 0 2px 12px;
				}
			}


			@include breakpoint(medium) {
				text-align: right;
				display: flex;
				flex-direction: column;
				padding-left: 20px;
				margin-left: 0;
				margin-right: 0;

				.button {
					display: block;
					margin: 20px 0 0;
					padding: 10px 18px;

					&:first-child {
						margin-top: auto;
					}
				}
			}

			@include breakpoint(medium only) {
				padding-left: 0;
			}

			@include breakpoint(small only) {
				margin-top: 20px;
			}
		}

		&-image {
			position: relative;
			min-height: 223px;

			@include breakpoint(medium) {
				a,
				picture {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}

				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}
	}


}
