.accordion {
	width: 100%;
	max-width: 1260px;
	margin: 70px auto;

	@include breakpoint(small only) {
		margin: 0 auto 40px;
	}
	

	&__inner {
		background-color: get-color('white');
		padding: 40px 20px;

		@include breakpoint(large) {
			padding: 40px 60px;
		}

		&::after {
			content: '';
			display: block;
			width: 100%;
			border-bottom: 1px solid get-color('light-grey');
		}
	}

	.accordionButton {
		position: relative;
		border-top: 1px solid get-color('light-grey');
		padding: 15px 40px 7px 0;
		cursor: pointer;

		&::after {
			content: '';
			position: absolute;
			top: 23px;
			right: 0;
			width: 14px;
			height: 14px;
			background: url('img/accordion-plus.svg') no-repeat;
			background-size: 14px 14px;
			transition: all 0.3s ease-in-out;
		}

		&.on::after {
			transform: rotate(45deg);
		}

		h3 {
			@include h2;
			text-transform: uppercase;
		}
	}

	&__image {
		margin-bottom: 40px;

		img {
			width: 100%;
		}
	}

	&__content {
		padding: 15px 0 25px;

		p,
		li {
			@include body-copy();
		}

		.multiple-buttons {
			text-align: left;
			margin-top: 25px;
		}
	}
}
