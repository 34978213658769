.contact {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto 65px;

	@include breakpoint(medium only) {
		padding: 0 20px;
	}

	&__details {
		margin-top: 60px;

		@include breakpoint(small only) {
			padding: 0 20px 41px;
			text-align: center;
		}

		@include breakpoint(medium) {
			padding-right: 20px;
		}

		@include breakpoint(large) {
			padding-right: 40px;
		}

		h3 {
			@include subheading();
			margin-bottom: 34px;
		}

		p {
			@include body-copy();
		}


	}

	&__form {
		background-color: get-color('white');
		padding: 52px 20px 17px;

		@include breakpoint(small only){
			border: none;
		}

		@include breakpoint(large) {
			padding: 60px 40px 27px;
		}

		h2 {
			@include h2;
			margin-bottom: 27px;
			text-transform: uppercase;
		}

		.button {
			min-width: 117px;
		}

		input[type="text"],
		input[type="email"],
		input[type="tel"],
		input[type="password"],
		textarea {
			background-color:#F0F8FC;
			letter-spacing: 1px;
		}

		.form__checkbox {
			letter-spacing: 1px;

			@include breakpoint(small only){
				margin-bottom: 38px;
			}

			input[type="checkbox"] {

				~ label::before {
					border: none;
					background:#F0F8FC;
					top: 5px;
				}

				~ label {
					color: get-color('dark-grey');
				}
			}
		}
	}


	span.field-validation-error {
		color: #FF0000;
		display: block;
		margin-bottom: 0px;
		transform: translateY(-23px);
		font-family: $secondary-font;
		font-size: 11px;
	}

	.form__checkbox {
		span.field-validation-error {
			letter-spacing: 0;
			transform: translateY(0);
		}
	}



}
