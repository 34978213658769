.breadcrumb {
    text-align: center;
    padding-top: 8px;

    &__list {
	    display: inline-block;
        text-align: left;
        margin: 0;
        padding: 0 5px 5px 5px;
        list-style-type: none;

        li {
	        display: inline-block;
	        white-space: nowrap;
            @include smallest();
            letter-spacing: 0.5px;

            &:nth-child(1n+3) {
                &:before {
                    content: '/';
                }
            }
        }

        li:before {
          padding: 0 4px;
        }
    }

    @include breakpoint(small only) {
      display: none;
    }
}
