.blog-detail {
	width: 100%;
	max-width: 1260px;
	margin: 47px auto 60px;

	@include breakpoint(large) {
		margin-bottom: 100px;
	}

	&__main {
		@include breakpoint(small only) {
			text-align: center;
			padding-right: 20px;
			margin-bottom: 81px;
		}

		@include breakpoint(medium down) {
			padding-left: 20px;
		}

		@include breakpoint(medium) {
			padding-right: 60px;
		}
	}

	.intro {
		margin-bottom: 0;
		max-width: none;
		padding: 0;

		@include breakpoint(medium) {
			text-align: left;
		}
	}

	&__post-date {
		@include subheading();
		font-style: normal;
	}

	&__post-categories {
		margin: 0 0 30px;
		list-style-type: none;
		@include subheading();

		li {
			display: inline;

			&::after {
				content: ', ';
			}

			&:last-child::after {
				content: '';
			}
		}

		a {
			color: inherit;
		}
	}

	&__content {
		p {
			@include body-copy();
		}

		p img {
			width: 100% !important;
			max-width: 100% !important;
			height: auto !important;
		}

		h2 {
			@include h2();
		}

		h3 {
			@include subheading();
		}

		li {
			@include bullet-list();
		}

		h2.subheading {
			@include subheading();
			font-size: 18px;
			text-transform: none;
			margin-bottom: 30px;
		}

	}

	&__sidebar {
		&-inner {
			padding: 40px 20px;
			background-color: #F0F8FC;


			// @include breakpoint(small only) {
			// 	border: none;
			// }

			@include breakpoint(large) {
				padding: 40px;
			}
		}

		h3 {
			@include h2;
			padding-bottom: 25px;

			@include breakpoint(small only) {
				text-align: center;
			}
		}

		ul {
			margin: 0;
			list-style-type: none;
		}

		li {
			margin-bottom: 36px;
			@include smallest();

			&:last-child {
				margin-bottom: 0;
			}
		}

		img {
			margin-bottom: 18px;
		}
	}
}
