.accommodation {
	width: 100%;
	max-width: 1104px;
	margin: 0 auto;

	&__items {
		justify-content: center;
	}

	&__item {
		padding: 0 20px;
		margin-bottom: 30px;

		@include breakpoint(small only) {
			margin-bottom: 15px;
		}

		&:last-of-type {
			margin-bottom: 60px;

			@include breakpoint(small only) {
				margin-bottom: 0px;
			}
		}

		@include breakpoint(large) {
			padding: 0 30px;
			margin-bottom: 0;
		}

		&-inner {
			background-color: get-color('white');

			@include breakpoint(small only){
				border: none;
			}
		}
	}

	&__image {
		margin-bottom: 33px;
	}

	&__content {
		text-align: left;

		h2 {
			@include h2;
			margin-bottom: 25px;
			text-transform: uppercase;
		}

		h3 {
			@include subheading();
			margin-bottom: 25px;
		}

		h2,
		h3 {
			a {
				color: inherit;
			}
		}

		p {
			@include body-copy();
		}
	}

	&__content,
	&__details {
		padding: 0 60px;

		@include breakpoint(medium down) {
			padding: 0 40px;
		}

		@include breakpoint(small only) {
			padding: 0 20px;
		}
	}

	&__details {
		text-align: left;

		h4 {
			@include price();
			color: get-color('blue');
		}
	}

	&__price {
		padding-top: 15px;
		padding-bottom: 7px;
	}

	.multiple-buttons {
		padding: 20px 0 40px;

	}
}
