.intro {
	width: 100%;
	max-width: 496px;
	margin: 0 auto 40px;
	padding: 55px 20px 0px;
	text-align: center;

	@include breakpoint(small only) {
		margin: 0 auto 0px;
		padding: 55px 20px 19px;
	}

	h1 {
		@include h1;
		margin-bottom: 40px;
		text-transform: uppercase;
	}

	h2 {
		@include subheading();
		margin-bottom: 32px;
		font-size: 18px;
	}

	p {
		@include body-copy;
		margin-bottom: 19px;
	}

	.multiple-buttons {
		padding-top: 37px;
		text-align: center;
	}
}
