.popup {
	@at-root .reveal-overlay {
		z-index: 60000;
	}

	&.reveal {
		max-width: 765px;
		border: 0;
		padding: 0;
		overflow: visible;
		padding: 30px;
		background: #fff;
		border: 1px solid get-color('light-grey');

		@include breakpoint(small only) {
			padding: 20px;
			// height: auto;
		}

		&:focus {
			outline: none;
		}
	}

	&__close {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 21px;
		height: 21px;
		background: url('img/close-thin-black.svg') no-repeat center center;
		background-size: 21px 21px;
		text-indent: -12345px;
		cursor: pointer;

		@include breakpoint(small only) {
			top: 30px;
			right: 30px;
		}
	}

	&__text {
		padding: 0px 5% 5%;
		text-align: center;

		@include breakpoint(small only) {
			padding: 5%;
		}

		img {
			margin-bottom: 15px;
			width: 100%;
		}

		h2 {
			@include h2;
			margin-bottom: 15px;
		}

		p {
			@include body-copy();

			a {
				color: get-color('blue');
				text-decoration: underline;
			}
		}

		.multiple-buttons {
			text-align: center;
		}
	}
}
