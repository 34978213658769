.blog-landing {
	width: 83.33333%;
	max-width: 1050px;
	margin: 0 auto 60px;
	// padding: 0 20px;
	text-align: center;
	
	.separator {
		margin-left: auto;
		margin-right: auto;
	}

	&__item {
		margin-bottom: 0px;

		&:not(.blog-landing__item--highlighted) {
			@include breakpoint(medium) {
				width: calc(50% - 34px);
				display: inline-block;
			}

			&:nth-child(even) {
				@include breakpoint(medium) {
					margin-right: 62px;
				}

				&:last-child {
					@include breakpoint(medium) {
						transform: translateX(34px);
					}
				}
			}
		}

		&--highlighted {
			margin-bottom: 52px;
		}
	}

	&__image {
		position: relative;
		min-height: 340px;
		background: no-repeat center center;
		background-size: contain;

		a,
		picture {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	&__content {
		padding: 40px 20px 50px;
		@include flex-align-center;
		background-color: get-color('white');
		text-align: left;

		&:is(.blog-landing__item--highlighted .blog-landing__content) {
			@include breakpoint(medium) {
				text-align: left;
			}

			// @include breakpoint(small only) {
			// 	border: none;
			// }

			@include breakpoint(large) {
				padding: 90px 60px;
			}
		}


		@include breakpoint(large) {
			padding: 40px 60px 50px;
		}

		h2 {
			@include h2;
			margin-bottom: 12px;

			a {
				color: inherit;
			}
		}

		h3 {
			@include subheading;
			font-size: 18px;
			margin-bottom: 30px;
		}

		p {
			@include body-copy();
		}
	}

	&__post-date {
		@include subheading();
		margin-bottom: 13px;
	}

	&__post-categories {
		margin-left: 0;
		list-style-type: none;
		@include subheading();
		margin-bottom: 10px;

		li {
			display: inline;

			&::after {
				content: ', ';
			}

			&:last-child::after {
				content: '';
			}
		}

		a {
			color: inherit;
		}
	}

	&__cta {
		padding-top: 10px;

		&:is(.blog-landing__item--highlighted .blog-landing__cta) {
			padding-top: 10px;
		}

		.button {
			margin-bottom: 0;
		}
	}
}
