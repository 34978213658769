.home {
	&__item {
		padding-top: 137px;
		background: url('img/separator-dark.svg') no-repeat center -50px;
		margin-bottom: 50px;

		@include breakpoint(small only) {
			margin-bottom: 0;
			padding-top: 117px;
			background-image: url('img/separator-dark-small.svg');
			background-position: center top;
		}
	}

	&__image {
		position: relative;
		min-height: 230px;

		@include breakpoint(medium) {
			min-height: 490px;
		}

		&-large {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		&-small {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translate(50%, -50%);
			border: 12px solid get-color('white');
			width: 207px;
			height: 61%;
			//max-height: 272px;
			z-index: 1;

			@include breakpoint(small only) {
				border-width: 8px;
				transform: none;
				top: initial;
				right: 20px;
				bottom: -75px;
				width: 124px;
				height: 124px;
				min-height: 0;
			}

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	&__content {
		@include flex-align-center();
		text-align: left;
		padding-top: 120px;
		padding-bottom: 60px;
		padding-left: 20px;
		padding-right: 20px;
		background-color: #fff;

		@include breakpoint(small only) {
			padding-top: 108px;
			border: none;
			padding-bottom: 10px;
		}

		@include breakpoint(medium) {
			padding-left: 163px;
		}

		@include breakpoint(large) {
			padding-right: 105px;
		}

		&-inner {
			max-width: 360px;
		}
	}


	&__item--image-right {
		.home {
			&__image {
				@include breakpoint(medium) {
					order: 2;
				}

				&-small {
					@include breakpoint(small only) {
						right: initial;
						left: 20px;
					}

					@include breakpoint(medium) {
						right: initial;
						left: 0;
						transform: translate(-50%, -50%);
					}
				}
			}

			&__content {
				@include breakpoint(medium) {
					display: flex;
					flex-direction: column;
					align-items: end;
					order: 1;
					padding-right: 163px;
					padding-left: 20px;
					background-color: #f0f8fc;
				}

				@include breakpoint(large) {
					padding-left: 105px;
				}
			}
		}
	}

	h2 {
		@include h2;
		margin-bottom: 26px;
		font-size: 29px;
		text-transform: uppercase;

		@include breakpoint(small only){
			margin-bottom: 10px;
		}

		a {
			color: inherit;
		}
	}

	h3 {
		@include subheading();
		margin-bottom: 18px;
		font-size: 18px;
		line-height: 20px;

		@include breakpoint(small only){
			margin-bottom: 10px;
		}

		a {
			color: inherit;
		}
	}

	p {
		@include body-copy();
	}

	.multiple-buttons {
		padding-top: 37px;
	}

	&__map {
		padding-top: 138px;
		background: url('img/separator-dark.svg') no-repeat center -50px;
		margin-bottom: 120px;

		@include breakpoint (small only) {
			margin-bottom: 62px;
			padding-top: 80px;
			background-image: url('img/separator-dark-small.svg');
			background-position: center top;
		}

		&-content {
			padding: 100px;

			@include breakpoint(medium down) {
				border: none;
				padding: 40px 20px;
			}

			&-text {
				margin-bottom: 50px;

				h2 {
					margin-bottom: 10px;
				}
			}
		}

		&-locations {

			h2 {
				font-family: $primary-font;
				font-style: normal;
				text-transform: none;
				font-size: 23px;
				margin-bottom: 19px;
				color: get-color('blue');
			}

				@include menu-list();
				margin: 0 0 30px;

				ol {
					margin-left: 15px;
				}

				li {
					position: relative;
					padding-right: 50px;
					margin-bottom: 10px;

					span {
						position: absolute;
						top: 3px;
						right: 0;
						min-width: 80px;

						@include breakpoint(medium down) {
							position: relative;
							display: block;
						}
					}
				}

				a {
					color: get-color('dark-grey');
				}

		}

		&-header {
			text-align: center;

			h2 {
				margin-bottom: 3px;
			}

			h3 {
				margin-bottom: 36px;
			}
		}

		&-map {
			position: relative;
			width: 100%;
			height: 510px;

			.map-container {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}

body.template-home {
	.intro {
		margin-top: 40px;
		margin-bottom: 38px;

		@include breakpoint(small only) {
			margin-top: 12px;
			margin-bottom: 0px;
			padding: 40px 20px 20px;
		}

		h1 {
			margin-bottom: 30px;
		}

		h2 {
			margin-bottom: 30px;
		}
	}
}
