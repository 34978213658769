.filter {
	text-align: center;
	margin-bottom: 25px;
	margin-top: 90px;

	@include breakpoint(small only) {
		padding: 0 20px;
	}

	&__inner {
		background-color: get-color('white');
		padding: 5px 20px;
	}

	span,
	.offers__filter-buttons,
	&__buttons {
		display: inline-block;
	}

	span {
		@include price;
		margin-right: 29px;
	}


	a,
	button {
		position: relative;
		text-transform: capitalize;
		margin: 0px 25px 0 15px;
		padding: 0;
		border: 0;
		background-color: transparent;
		@include smallest;
		color: get-color('blue');
		font-weight: 600;

		&::after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 26px;
			border-bottom: 1px solid get-color('blue');
			opacity: 0;
			transition: all 0.3s ease-in-out;
		}

		&:hover,
		&.on {
			background-color: transparent;
			color: get-color('dark-grey');

			&::after {
				opacity: 1;
			}
		}
	}

	select {
		border: 0;
		@include smallest;
		background-color: transparent;
		margin-left: 15px;
	}
}
