.detail {
	text-align: center;
	width: 100%;
	max-width: 1260px;
	margin: 70px auto 50px;

	@include breakpoint(small only){
		margin-bottom: 32px;

		.multiple-buttons .button {
			margin: 0 12px 20px;
		}
	}

	&__inner {
		background-color: #F0F8FC;
		padding: 55px 20px 45px;
	}

	&__features {
		width: 100%;
		max-width: 650px;
		margin: 0 auto 20px;
		padding: 0 15px;
		text-align: left;

		h2 {
			@include h2;
			margin-bottom: 25px;
			text-transform: uppercase;
		}

		&-list {
			text-align: left;
			padding-left: 15px;

			ul {
				margin: 0;
			}

			li {
				@include bullet-list();
				margin-bottom: 0;

				&::marker {
					margin-right: 10px;
				}
			}
		}
	}

	&__cta {
		padding-top: 7px;
		max-width: 650px;
		width: 100%;
		text-align: left;
		margin: auto;
		padding-left: 15px;
		padding-right: 15px;
	}

	&__rate {
		@include price;
		color: get-color('blue');
		margin-bottom: 35px;
	}
}
