.footer {
	width: 100%;
	background-color: get-color('blue');

	@include breakpoint(small only) {
		text-align: center;
	}

	&__newsletter {
		max-width: 1260px;
		margin: 0 auto;
		padding: 50px 0 100px;
		background: url('img/separator-white.svg') no-repeat center bottom;
		background-color: get-color('blue');

		@include breakpoint(small only) {
			padding-left: 20px;
			padding-right: 20px;
			text-align: left;
			background-image: url('img/footer-separator-small.svg');
		}

		h2 {
			@include price();
			margin-bottom: 18px;
			color: get-color('white');
		}

		input[type="text"],
		input[type="email"] {
			width: 100%;
			height: 33px;
			margin-bottom: 7px;
			letter-spacing: 1px;
			font-weight: 600;
			background-color: get-color('pale-blue');

			@include breakpoint(small only) {
				margin-bottom: 20px;
			}
		}

		button[type="submit"],
		input[type="submit"] {
			height: 33px;
			background-color: get-color('white');
			color: get-color('blue');
			border: 1px solid #fff;

			@include breakpoint(medium) {
				width: 100%
			}

			&:hover {
				background-color: get-color('gold');
				color: get-color('white');
			}

			@include breakpoint(small only) {
				margin-top: 20px;
			}
		}

		form .cell {
			padding-right: 30px;

			@include breakpoint(small only) {
				padding-right: 0px;
			}
		}

		label {
			@include smallest();
			color: get-color('white');
			letter-spacing: 1px;
			font-weight: 600;
		}

		form .cell.medium-order-3 {

			@include breakpoint(medium) {
				padding-right: 0;
			}
		}
	}

	&__main {
		background-color: get-color('blue');
	}

	&__inner {
		max-width: 1260px;
		margin: 0 auto;
		padding: 50px 0 50px;

		@include breakpoint(medium only){
			padding: 50px 20px;
		}

		@include breakpoint(small only) {
			padding-top: 40px;
		}
	}

    &__social {
	    margin: 0 0 20px 0;
	    list-style-type: none;

	    li {
		    display: inline-block;
		    margin: 0 8px;
	    }

	    img,
	    svg {
		    width: auto;
		    height: 20px;
	    }

			@include breakpoint(small only) {
				margin: 20px 0;
			}
    }

    &__primary,
    &__secondary {

			@include breakpoint(medium only) {
				margin-bottom: 20px;
			}
	    ul {
		    margin: 0;
		    list-style-type: none;
				font-weight: 600;
	    }

	    li {
		    white-space: nowrap;

				@include breakpoint(small only) {
					margin-bottom: 7px;
				}
	    }

		a {
			position: relative;
			@include smallest();
			color: get-color('white');

			&:hover {
				color: get-color('pale-blue');
			}

			&::after {
				content: '';
				position: absolute;
				bottom: -2px;
				left: 0;
				width: 0;
				border-bottom: 1px solid get-color('white');
				transition: width 0.3s ease-in-out;
			}

			&:hover,
			&:active {
				&::after {
					width: 30px;
					border-bottom: 1px solid get-color('pale-blue');
				}
			}
		}
    }

    &__copyright {
	    @include terms-conditions();
		color: get-color('white');

		a {
			color: inherit;

			&:hover {
				color: get-color('pale-blue');
			}
		}
	}

	&__social {
		text-align: right;

		@include breakpoint(small only){
			text-align: center;
		}

		path {
			fill: #fff;
		}

		a:hover {

			path {
				fill: get-color('pale-blue');
			}
		}
	}
}
