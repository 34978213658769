.slider {
	position: relative;
	text-align: center;

	.slider__inner {
		width: 100vw;
		height: 100vh;
	}

	&__image {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;
		display: flex;

		picture {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	}

	&__video {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		iframe,
		video {
			position: absolute;
		}
	}

	&__caption {
		position: absolute;
		top: 0;
		left: 40px;
		right: 40px;
		height: 100%;
		@include flex-align-center();
		z-index: 1;

		h2 {
			@include slider-caption();
			color: #fff;
		}

		p {
			@include subheading();
			color: #fff;
			font-size: 22px;
			line-height: 25px;
		}
	}

	.reform-slider-arrows {
		margin: 0;
		list-style: none;

		.reform-prev,
		.reform-next {
			position: absolute;
			top: 50%;
			z-index: 1000;
			text-indent: -123456px;
			width: 20px;
			height: 20px;
		}

		.reform-prev {
			left: 20px;
			@include arrow("left", #fff, #fff)
		}

		.reform-next {
			right: 20px;
			@include arrow("right", #fff, #fff)
		}
	}

	.reform-pagination {
		position: absolute;
		z-index: 1000;
		bottom: 50px;
		left: 0;
		right: 0;
		margin: 0;
		list-style-type: none;

		@media screen and (max-width: $offcanvas-breakpoint) {
			bottom: 80px;
		}

		button {
			cursor: pointer;
		}

		li {
			display: inline-block;
			margin: 0 7px;

			&.reform-pagination-dot button {
				width: 10px;
				height: 10px;
				border-radius: 100%;
				background-color: #fff;
				border: 1px solid #fff;
				text-indent: -123456px;
				cursor: pointer;
				transform: translateY(-4px);
			}

			&.reform-pagination-dot button:hover,
			&.reform-pagination-dot.active button {
				background-color: transparent;
			}
		}

		.reform-pagination-prev,
		.reform-pagination-next {
			position: relative;
			text-indent: -123456px;
			width: 12px;
			height: 12px;
			cursor: pointer;
		}

		.reform-pagination-prev {
			@include arrow("left", #fff, #fff)
		}

		.reform-pagination-next {
			@include arrow("right", #fff, #fff)
		}
	}

	&__scroll {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 20px;
		z-index: 1000;

		@media screen and (max-width: $offcanvas-breakpoint) {
			bottom: 60px;
		}

		button {
			position: relative;
			display: inline-block;
			width: 20px;
			height: 20px;
			text-indent: -123456px;
			transition: all 0.3s ease-in-out;
			cursor: pointer;

			@include arrow("down", #fff, #fff)

			&:hover,
			&:active {
				transform: translateY(5px);
			}
		}
	}
}
