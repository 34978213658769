.standard-content {
	width: 100%;
	max-width: 740px;
	margin: 0 auto 74px;
	padding: 0 20px;
	text-align: center;

	@include breakpoint(small only) {
		margin: 0 auto 37px;
	}

	img {
		width: auto !important;
		max-width: 100% !important;
		height: auto !important;
	}

	h2 {
		@include h2;
		text-transform: uppercase;
		margin-bottom: 25px;

		@include breakpoint(small only) {
			margin: 20px 0;
		}
	}

	p,
	li {
		@include body-copy();
	}

	p {
		text-align: center;
		margin-bottom: 25px;

		@include breakpoint(small only) {
			margin: 20px 0;
		}
	}

	p img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	ul,
	ol {
		text-align: left;
		max-width: 340px;
		width: 100%;
		margin: 0 auto 25px;
	}

	&__cta {
		text-align: center;
	}

	&__full-width {
		margin: 25px 0;

		@include breakpoint(small only) {
			margin: 20px 0;
		}
	}

	&__cta {
		a.button {
			margin: 25px 0;

			@include breakpoint(small only) {
				margin: 20px 0;
			}
		}

	}
}
