.directions {
	position: relative;
	margin-bottom: 0;
	
	&__map {
		position: relative;
		text-align: left;
		min-height: calc(100vh - 116px - 31px);
		
		@include breakpoint(large up) {
			min-height: calc(100vh - 185px);
		}
		
		.map-container {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
}