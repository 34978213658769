@charset 'utf-8';

$offcanvas-breakpoint: 970px;

$foundation-palette: (
	primary: #2875A0, // blue
	secondary: #767676, // default
	success: #3adb76, // default
	warning: #ffae00, // default
	alert: #cc4b37, // default
	'black': #000,
	'white': #fff,
	'grey': #707070,
	'dark-grey': #434343,
	'light-grey': #e5e3e3,
	'mid-grey': #747474,
	'blue': #2875A0,
	'pale-blue': #D6EBF7,
	'gold': #C88E09
);

$body-background: #fff;

$primary-font: 'FS Sally', serif;
$secondary-font: 'Montserrat', sans-serif;
