.separator {
  padding: 40px 0 42px;
  background: url('img/separator-dark.svg') no-repeat center center;
  margin-bottom: 53px;

  @include breakpoint(small only) {
    padding: 42px 0;
    margin-bottom: 30px;
    max-width: 80%;
  }
}
