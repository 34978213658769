.header {
	position: fixed;
	z-index: 2000;
	top: 0;
	left: 0;
	width: 100%;
	background-color: $body-background;
	transition: background-color 0.3s ease-in-out;
	box-shadow: 0 0 10px rgba(#000, 0.3);

	// @include breakpoint(small only) {
	// 	box-shadow: 0 0 20px rgba(#000, 0.3);
	// }

	&__inner {
		position: relative;
		width: 100%;
	}

	&__open-off-canvas {
		position: absolute;
		top: 39px;
		left: 53px;
		width: 31px;
		height: 16px;
		text-indent: -123456px;
		background: url('img/burger-black.svg') no-repeat;
		background-size: 31px 16px;
		cursor: pointer;
		border: 0 !important;
		background-color: transparent !important;

		@media screen and (max-width: $offcanvas-breakpoint) {
			top: 24px;
			left: 20px;
		}
	}

	&__open-booking {
		position: absolute;
		top: 30px;
		right: 53px;
		margin: 0;

		@media screen and (max-width: $offcanvas-breakpoint) {
			box-shadow: 0 0 10px 0 rgba(#000, 0.3);
			position: fixed;
			top: auto;
			bottom: 0px;
			left: 0;
			width: 100%;
			background-color: get-color('white');
			color: get-color('blue');
			border: 1px solid #fff;
		}

		&:hover {
			background-color: get-color('gold');
			border-color: get-color('gold');
			color: get-color('white');
		}

		@media screen and (max-width: 970px) {
			border: none;
		}
	}

	&__off-canvas-toggle {
		top: 39px;
		transition: all 0.3s ease-in-out;

		@include breakpoint(small only) {
			top: 25px;
		}

		svg {
			rect {
				fill: get-color('blue');
			}
		}
	}

	&__mobile-call {
		position: absolute;
		top: 20px;
		right: 20px;
		width: 28px;
		height: 28px;
		text-indent: -12345px;
		margin: 0;
		padding: 0;
		border: 0;
		background: none transparent;
		display: none;

		@media screen and (max-width: $offcanvas-breakpoint) {
			display: block;
		}

		&:active,
		&:focus,
		&:hover {
			background-color: transparent;
		}

		img,
		svg {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			path {
				stroke: get-color('blue');
			}
		}
	}

	&__logo {
		width: 80%;
		max-width: 216px;
		margin: 0 auto;
		padding-top: 28px;
		padding-bottom: 34px;
		transition: all 0.3s ease-in-out;
		
		@include breakpoint(small only) {
			max-width: 160px;
			padding-top: 10px;
			padding-bottom: 17px;
		}

		&--transparent,
		&--compact {
			display: none;
		}

		img {
			width: 100%;
		}
	}

	&__top-nav {
		position: absolute;
		top: 32px;
		letter-spacing: 0.5px;
		transition: all 0.3s ease-in-out;

		&--left {
			left: 108px;
		}

		&--right {
			right: 180px;
		}

		a {
			position: relative;
			color: get-color('blue') !important;
			font-weight: 600;

			&:hover {
				color: get-color('dark-grey') !important;
			}

			&::after {
				content: '';
				position: absolute;
				bottom: -2px;
				left: 0;
				width: 0;
				border-bottom: 1px solid get-color('blue');
				transition: width 0.3s ease-in-out;
			}

			&:hover,
			&:active {
				&::after {
					width: 30px;
					border-bottom: 1px solid get-color('dark-grey');
				}
			}
		}

		@media screen and (max-width: $offcanvas-breakpoint) {
			display: none;
		}

		ul {
			margin: 0;
			list-style-type: none;
		}

		li {
			display: inline;
			margin-left: 5px;

			&:first-child {
				margin-left: 0;
			}

			&.on {

				a {

				}
			}
		}

		a {
			@include smallest();
			margin-right: 10px;
		}
	}







	//////////////////////
	// Styles that are applied to only the transparent header
	//////////////////////
	&--transparent {
		background-color: transparent;
		background: linear-gradient(to bottom, rgba(0,0,0,0.58) 0%,rgba(0,0,0,0) 100%);
		box-shadow: none;
		padding-bottom: 40px;

		.header {
			&__open-off-canvas {
				background-image: url('img/burger-white.svg');
			}

			&__logo {
				&--transparent {
					display: block;
				}

				&--normal {
					display: none;
				}
			}

			&__top-nav {
				a {
					position: relative;
					color: #fff !important;
					font-weight: 600;

					&:hover {
						color: get-color('pale-blue') !important;
					}

					&::after {
						content: '';
						position: absolute;
						bottom: -2px;
						left: 0;
						width: 0;
						border-bottom: 1px solid get-color('white');
						transition: width 0.3s ease-in-out;
					}

					&:hover,
					&:active {
						&::after {
							width: 30px;
							border-bottom: 1px solid get-color('pale-blue');
						}
					}
				}
			}

			&__off-canvas-toggle {
				svg {

					rect {
						fill: #fff;
					}
				}
			}

			&__open-booking {
				background-color: get-color('white');
				border-color: get-color('blue') !important;
				color: get-color('blue') !important;
				font-family: $secondary-font;

				&:hover {
					background-color: get-color('gold') !important;
					color: get-color('white') !important;
					border-color: get-color('gold') !important;
				}
			}

			&__mobile-call svg path {
				stroke: #fff;
			}
		}
	}

	&--transparent,
	&--compact {
		.header {
			&__open-booking {
				background-color: get-color('white');
				border-color: get-color('blue');
				color: get-color('blue');
				font-family: $secondary-font;

				&:hover {
					background-color: get-color('gold');
					border-color: get-color('gold');
					color: get-color('white');
				}
			}
		}
	}

	//////////////////////
	// compact header
	//////////////////////

	&--compact {

		.header__off-canvas-toggle {
			top: 30px;
		}

		.header__top-nav--right,
		.header__top-nav--left {
			top: 23px;
		}

		.header__open-booking {
				top: 20px;

				@media screen and (max-width: $offcanvas-breakpoint) {
					top: auto;
					bottom: 0px;
				}
			}

		.header__logo {
			&--compact {
				display: block;
				padding-top: 20px;
				padding-bottom: 20px;
			}

			&--normal {
				display: none;
			}
		}
 	}

	&__off-canvas-toggle {
		position: absolute;
		top: 39px;
		left: 53px;
		cursor: pointer;

		@include breakpoint(small only) {
			top: 25px;
			left: 23px;
		}

		rect {
			transform-origin: left center;
			transition: transform 0.3s ease-in-out;
			fill: get-color('dark-grey');

			&:nth-child(2) {
				transform: scaleX(1);
			}

			&:nth-child(3) {
				transform: scaleX(1);
			}
		}

		&:hover rect {
			&:nth-child(2) {
				transform: scaleX(0.75);
			}

			&:nth-child(3) {
				transform: scaleX(0.5);
			}
		}
	}

	svg {
			width: 30px;
			height: auto;
		}
}
