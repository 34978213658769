.neighbourhood {
  position: relative;
  overflow: hidden;
  margin-bottom: 80px;
  margin-top: 80px;

  &__map {
    position: relative;
    text-align: left;

    @include breakpoint(small only) {
      height: 90vh;
      min-height: 0;
    }
    
    @include breakpoint(medium) {
      height: 620px;
    }

    &-wrap {
      position: relative;
    }

    &-content {
      top: 50%;
      bottom: auto;
      max-width: 90%;
      transform: translate(100%, -50%);
      right: 0px;
      position: absolute;
      transition: transform 0.3s linear;
      width: 100%;
      background-color: #fff;
      padding: 0px 0 20px;

      @include breakpoint(medium) {
        transform: translateX(calc(100% + 53px));
        max-width: 465px;
        top: 0;
        right: 53px;
        bottom: 0;
      }

      @include breakpoint(small only) {
      }

      &-inner {
        padding: 5%;
        
        @include breakpoint(small only) {
          border: 1px solid get-color('light-grey');
          padding: 30px 15px;
        }
      }

      &.marker-active {
        transform: translateX(0);

        @include breakpoint(small only) {
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      img {
        @include breakpoint(medium) {
          margin-bottom: 15px;
        }
      }

      h3 {
        @include h2;
        margin-bottom: 20px;
      }

      h4 {
        @include subheading;
        margin-bottom: 35px;
      }

      p {
        @include body-copy;
        margin-bottom: 25px;
      }

      &--close {
        position: absolute;
        top: 15px;
        right: 15px;
        width: 24px;
        height: 24px;
        background: url("img/close-blue-outlined.svg") no-repeat center center;
        background-size: 100%;
        cursor: pointer;

        &:focus {
          outline: transparent;
        }

        @include breakpoint(small only) {
          top: 0px;
          right: 0px;
        }
      }
    }
  }

  &__marker {
    top: 50%;
    bottom: auto;
    max-width: 90%;
    transform: translate(100%, -50%);
    right: 0px;
    position: absolute;
    transition: transform 0.3s linear;
    width: 100%;
    background-color: #fff;

    @include breakpoint(medium) {
      transform: translateX(calc(100% + 53px));
      max-width: 465px;
      top: 0;
      right: 53px;
      bottom: 0;
      padding: 0 0 20px;
    }

    @include breakpoint(small only) {
    }

    &-inner {
      padding: 5%;
      
      
    }

    &.neighbourhood__marker--active {
      transform: translateX(0);

      @include breakpoint(small only) {
        left: 50%;
		right: auto;
        transform: translate(-50%, -50%);
      }
    }

    img {
      @include breakpoint(medium) {
        margin-bottom: 15px;
      }
    }

    h3 {
      @include h2;
      margin-bottom: 20px;
    }

    h4 {
      @include subheading;
      margin-bottom: 35px;
    }

    p {
      @include body-copy;
      margin-bottom: 25px;
    }

    &-close {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 24px;
      height: 24px;
      background: url("img/close-blue-outlined.svg") no-repeat center center;
      background-size: 100%;
      cursor: pointer;

      &:focus {
        outline: transparent;
      }

      @include breakpoint(small only) {
        top: -8px;
        right: -8px;
      }
    }
  }

  &__filters {
    padding: 0;
    list-style: none;
    text-align: center;
    background-color: #fff;
    margin-bottom: 20px;

    @include breakpoint(medium) {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      padding: 10px;
    }

    @include breakpoint(small only) {
    }

    p {
      @include price;
      margin-right: 29px;
      display: inline-block;
    }

    ul {
      display: none;
      margin: 0 auto;
      padding: 10px 0;
      text-align: center;

      @at-root .neighbourhood__filters--expanded#{&} {
        display: block;
      }

      @include breakpoint(medium) {
        display: inline-block;
        padding: 0;
      }

      li {
        display: block;
        padding: 0 0 10px 0;

        @include breakpoint(medium) {
          display: inline-block;
          padding: 0;
        }

        input[type="radio"] {
          display: none;
        }
      }
    }
  }

  &__filter {
    color: get-color("blue");
    position: relative;
    text-transform: uppercase;
    margin: 0px 25px 0 15px;
    padding: 0;
    border: 0;
    background-color: transparent;
    @include smallest;
    color: get-color("blue");
    font-weight: 400;
    letter-spacing: 0.1em;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 26px;
      border-bottom: 1px solid get-color("blue");
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }

    @at-root input[type="radio"]:checked ~ #{&} {
      background-color: transparent;
      color: get-color("dark-grey");

      &::after {
        opacity: 1;
      }
    }

    &:hover,
    &.on {
      background-color: transparent;
      color: get-color("dark-grey");

      &::after {
        opacity: 1;
      }
    }

    &-all-wrapper {
      display: inline-block;
      
      @include breakpoint(small only) {
        margin-bottom: 7px;
      }
    }

    &-toggle {
      font-family: $secondary-font;
      position: relative;
      display: inline-block;
      min-width: 60px;
      padding: 12px 35px 12px 12px;
      font-size: rem-calc(11px);
      font-weight: 400;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      text-align: center;
      color: get-color('dark-grey');
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 20px;
        height: 5px;
        background: url("img/chevron-down-blue.svg") no-repeat center 100%;

        @at-root .neighbourhood__filters--expanded #{&} {
          transform: rotate(180deg);
          transform-origin: center;
        }
      }

      @include breakpoint(medium) {
        display: none;
      }
    }

    &-all {
      display: inline-block;
      color: get-color("blue");
      position: relative;
      text-transform: uppercase;
      margin: 0px 25px 0 15px;
      padding: 0;
      border: 0;
      background-color: transparent;
      @include smallest;
      color: get-color("blue");
      font-weight: 400;
      letter-spacing: 0.1em;
      cursor: pointer;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 26px;
        border-bottom: 1px solid get-color("blue");
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }

      &:hover,
      &.on {
        background-color: transparent;
        color: get-color("dark-grey");

        &::after {
          opacity: 1;
        }
      }
    }
  }
}
