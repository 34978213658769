.menus {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto 60px;

	&__inner {
		padding: 20px;
		background-color: #F0F8FC;

		@include breakpoint(medium) {
			padding: 40px;
		}

		@include breakpoint(large) {
			padding: 60px;
		}
	}

	&__image {
		position: relative;
		min-height: 300px;

		picture {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			@include breakpoint(medium) {
				width: calc(100% - 40px);
			}

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}
	}

	&__heading {
		h2 {
			@include h2;
			margin-bottom: 24px;
			text-transform: uppercase;
		}
	}

	&__content {
		@include breakpoint(small only) {
			padding-top: 40px;
		}
	}

	&__list {
		@include menu-list();
		margin: 0 0 38px;
		list-style-type: none;

		li {
			position: relative;
			padding-right: 50px;
			font-weight: 400;

			span {
				position: absolute;
				top: 3px;
				right: 0;
				@include smallest();
				color: get-color('blue');
			}
		}

		a {
			color: get-color('dark-grey');
		}
	}

	&__times {
		h3 {
			@include h2;
			text-transform: uppercase;
			font-weight: 400;
			margin-bottom: 11px;
		}

		ul {
			@include menu-list();
			margin: 0 0 40px;
			list-style-type: none;
			font-weight: 400;
			line-height: 22px;
		}

		p {
			@include menu-list();
			font-weight: 400;
			line-height: 22px;
		}
	}
}
