.social-wall {
	text-align: center;
	margin-top: -10px;
	margin-bottom: 25px;

	h3 {
		@include h2;
		margin-bottom: 20px;
		text-transform: uppercase;

		a {
			color: inherit;
		}

		@include breakpoint(small only) {
			margin-bottom: 5px;
		}
	}

	p {
		@include menu-list();
		margin-bottom: 35px;
		font-weight: normal;

		a {
			color: inherit;
		}
	}

	&__feed {
		.crt-feed {
			@include breakpoint(medium) {
				width: calc(100% + 24px);
				margin-left: -12px;
				margin-right: -12px;
			}
		}

		.crt-grid-post {
			@include breakpoint(medium) {
				padding: 0 10px;
			}
		}
		
		.crt-post-hover {
		  display: none !important; // client request to hide the hover state
		}
	}



}

.social-wall.wrap--narrow {
	max-width: 1050px;
	width: 100%;
	margin: -10px auto 25px;

	@include breakpoint(medium down){
		padding-right: 40px;
		padding-left: 40px;
	}
}
