.off-canvas {
	z-index: 50000;
	width: 100%;
	transform: translateX(-100%);
	background-color: get-color('blue');

	&.is-open {
		transform: translateX(0);
	}

	&__inner {
		padding: 20px;

		@include breakpoint(large) {
			padding: 10px 53px;
		}
	}

	&__header {
		position: relative;
		margin-bottom: 60px;
	}

	&__main {
		margin: 135px auto 0;
		max-width: 1260px;

		@include breakpoint(small only) {
			margin-top: 75px;
		}
	}

	&__close {
		position: absolute;
		top: 20px;
		left: 0;
		width: 21px;
		height: 21px;
		background: url('img/close-thin-white.svg') no-repeat;
		background-size: 21px 21px;
		cursor: pointer;
		text-indent: -12345px;

		&:focus,
		&:active {
			outline: none;
		}

		@include breakpoint(small only) {
			top: 0;
		}
	}

	&__logo {
		width: 176px;
		margin: 0 auto;
	}

	&__nav-primary {
		margin-bottom: 65px;
		text-transform: uppercase;

		a {
			transition: color 0.3s ease-in-out;
			color: get-color('white');

			&:hover,
			&:active {
				color: get-color('pale-blue');
			}
		}

		> ul {
			margin: 0;
			list-style-type: none;

			> li {
				margin-bottom: 27px;

				> a {
					@include h2;
					color: get-color('white');
				}

				&.open > a {
					color: get-color('pale-blue');
				}

				> ul {
					margin: 25px 0 30px 35px;
					list-style-type: none;
					display: none;

					@include breakpoint(small only) {
						margin-left: 0;
					}

					> li {
						margin-bottom: 8px;

						@include breakpoint(small only) {
							line-height: 1;
							// margin-bottom: 20px;
						}

						> a {
							@include h2;
							font-size: 20px;
							color: get-color('white');
						}
					}
				}
			}
		}
	}

	&__nav-header {
		display: none;


		@media screen and (max-width: $offcanvas-breakpoint) {
		 	display: block;
			 margin-top: -50px;
			 margin-bottom: 50px;
		}

		ul {
			margin: 0 auto;
			list-style-type: none;
		}

		a {
			@include smallest();
			font-weight: 600;
			color: get-color('white');
		}
	}

	.footer__newsletter {

		@include breakpoint(small only) {
			padding: 0 20px 50px !important;
		}
	}

	&__sidebar {
		max-width: 310px;
		width: 100%;
		margin-bottom: 100px;

		img {
			margin-bottom: 35px;
		}

		h3 {
			@include h2;
			margin-bottom: 12px;
		}

		@media screen and (max-width: 1050px) {
			display: none;
		}

	}

	&__offer-price {
		@include price;
		margin-bottom: 24px;
	}

	&__offer-inner {
		background-color: get-color('white');
		// border: 1px solid get-color('light-grey');

		h3,
		.off-canvas__offer-price {
			padding: 0 26px;
		}

		.off-canvas__offer-cta {
			padding-bottom: 30px;
			// padding: 0 20px;
			text-align: center;

			a {
				margin: 0 10px;
			}
		}

	}

	.footer__newsletter {
		padding: 120px 0 50px;
		background: url('img/separator-white.svg') no-repeat center top;
		// max-width: unset;
		max-width: 1260px;

		@include breakpoint(small only) {
			background: none;
		}
	}
}
