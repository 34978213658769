.cookie-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 20000;
	padding: 40px 0 33px;
	display: none;
	background-color: #fff;
	
	&__inner {
		max-width: 1260px;
		margin: 0 auto;
		
		.cell {
			position: relative;
			
			@include breakpoint(small only) {
				padding: 0 20px;
			}
		}
		
		p {
			@include body-copy();
			margin: 0 160px 0 0;
			
			@include breakpoint(small only) {
				margin: 0 0 60px;
			}
			
			a {
				color: inherit;
				text-decoration: underline;
				transition: color 0.3s ease-in-out;
			}
		}
	}
	
	&__accept {
    	position: absolute;
		top: 0;
		right: 0;
		width: 140px;
		height: 35px;
		cursor: pointer;
		padding: 10px 20px 8px;
		
		@include breakpoint(small only) {
			top: initial;
			right: initial;
			left: 50%;
			bottom: -20px;
			transform: translateX(-50%);
			
		}
  }
}