.gallery {
	width: 100%;
	max-width: 1260px;
	margin: 0 auto 120px;
	padding: 0 10px;

	@include breakpoint(large) {
		padding: 0 30px;
	}

	&__grid {}

	&__item {
		width: 33%;
		float: left;
		padding: 10px;

		@include breakpoint(medium only) {
			width: 50%;
		}

		@include breakpoint(small only) {
			width: 100%;
		}

		&-sizer {
			width: 33%;
			padding: 10px;

			@include breakpoint(medium only) {
				width: 50%;
			}

			@include breakpoint(small only) {
				width: 100%;
			}
		}

		a {
			position: relative;
			display: block;

			&::before {
				content: '';
				position: absolute;
				z-index: 5;
				bottom: 0;
				right: 0;
				width: 14px;
				height: 14px;
				background: url('img/accordion-plus.svg') no-repeat center center;
				background-size: 14px 14px;
			}

			img {
				margin-bottom: 10px;
			}

			p {
				@include subheading();
			}
		}
	}

	&__item-title {
			min-height: 20px;
			@include subheading();
	}

	&__modal {
		padding: 0;

		&-inner {
			position: relative;
			width: 83.333%;
			margin: 0 auto;
			padding: 70px 0;

			@include breakpoint(large) {
				padding: 120px 0;
			}
		}

		&-close {
			position: absolute;
			z-index: 10;
			top: 20px;
			right: 0;
			width: 28px;
			height: 28px;
			text-indent: -12345px;
			cursor: pointer;
			background: url('img/close-thin-black.svg') center center no-repeat;
			background-size: 28px 28px;

			@include breakpoint(large) {
				top: 50px;
			}
		}

		&-image {
			position: relative;
			
			@include breakpoint(large) {
				max-height: calc(100vh - 201px);
				display: flex;
				
				img {
					object-fit: contain;
				}
			}
		}

		&-prev,
		&-next {
			position: absolute;
			top: 50%;
			margin-top: -8px;
			width: 21px;
			height: 21px;
			text-indent: -12345px;
			cursor: pointer;
			background: no-repeat;
			background-size: 27px 21px;
			transition: all 0.3s ease-in-out;

			&:hover,
			&:active {
				width: 27px;
			}
		}

		&-prev {
			left: -30px;
			background-image: url('img/carousel-prev.svg');
			background-position: left center;

			@include breakpoint(large) {
				left: -75px;
			}
		}

		&-next {
			right: -30px;
			background-image: url('img/carousel-next.svg');
			background-position: right center;

			@include breakpoint(large) {
				right: -75px;
			}
		}

		&-content {
			padding: 20px 0 0;
		}

		&-caption {
			@include subheading();

			@include breakpoint(small only) {
				text-align: center;
				margin-bottom: 15px;
			}
		}

		&-cta {
			text-align: center;
			@include price();
			color: get-color('blue');

			@include breakpoint(medium) {
				text-align: right;
			}

			.button {
				margin-left: 15px;
				font-family: $secondary-font;
				font-style: normal;
				font-weight: 600;
				transform: translateY(6px);
			}


				transform: translateY(-15px);

		}

		&-iframe {
			position: relative;
			padding-bottom: 56.25%; /* 16:9 */
			padding-top: 25px;
			height: 0;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}

	}
}
