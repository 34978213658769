.curtain {
	position: absolute;
	z-index: 500;
	width: 100%;
	height: 100%;
	background-color: #fff;
	transform-origin: left;

	&--right {
		transform-origin: right;
	}
}







.transition {
	// transition: transform cubic-bezier(0.5, 0.01, 0.3, 1.01), opacity cubic-bezier(0.5, 0.01, 0.3, 1.01);
	transition: all cubic-bezier(0.5, 0.01, 0.3, 1.01);
	will-change: transform, opacity;
	backface-visibility: hidden;

	&--250  { transition-duration:  250ms; }
	&--500  { transition-duration:  500ms; }
	&--750  { transition-duration:  750ms; }
	&--1000 { transition-duration: 1000ms; }
	&--1250 { transition-duration: 1250ms; }
	&--1500 { transition-duration: 1500ms; }
	&--1750 { transition-duration: 1750ms; }
	&--2000 { transition-duration: 2000ms; }
	&--2250 { transition-duration: 2250ms; }
	&--2500 { transition-duration: 2500ms; }
	&--3000 { transition-duration: 3000ms; }
	&--3500 { transition-duration: 3500ms; }
	&--stacking {
		transition-duration: 1000ms;

		&:nth-child(3n+2) {
			transition-duration: 2000ms;
		}
		&:nth-child(3n+3) {
			transition-duration: 3000ms;
		}
	}

	&--delay-100  { transition-delay:  100ms; }
&--delay-200  { transition-delay:  200ms; }
&--delay-300  { transition-delay:  300ms; }
&--delay-400  { transition-delay:  400ms; }
&--delay-500  { transition-delay:  500ms; }
&--delay-600  { transition-delay:  600ms; }
&--delay-700  { transition-delay:  700ms; }
&--delay-800  { transition-delay:  800ms; }
&--delay-900  { transition-delay:  900ms; }
&--delay-1000 { transition-delay: 1000ms; }

&--stacking-4-column {
	transition-duration: 1000ms;

	&:nth-child(4n+2) {
		transition-duration: 1750ms;
	}
	&:nth-child(4n+3) {
		transition-duration: 2500ms;
	}
	&:nth-child(4n+4) {
		transition-duration: 3000ms;
	}
}

&--stacking-1 {
		transition-duration: 1000ms;
	}

	&--stacking-2 {
		transition-duration: 2000ms;
	}

	&--stacking-3 {
		transition-duration: 3000ms;
	}

	&--stagger-carousel-items {
		.slick-slide {
			&:nth-child(2) {
				transition-delay: 300ms !important;
				* {
					transition-delay: 300ms !important;
				}
			}

			&:nth-child(3) {
				transition-delay: 600ms !important;
				* {
					transition-delay: 600ms !important;
				}
			}

			&:nth-child(4) {
				transition-delay: 900ms !important;
				* {
					transition-delay: 900ms !important;
				}
			}
		}
	}
}

.transform {
	&--zero-width {
		transform: scale(0,1);
	}


	&--will-slide-in {
		transform: translateX(-75vw);
	}
	&--completed-slide-in {
		transform: translateX(0);
	}

	&--will-slide-up {
		transform: translateY(10%);
	}
	&--completed-slide-up {
		transform: translateY(0);
	}


	&--will-slide-in-right {
		transform: translateX(75vw);
	}
	&--completed-slide-in-right {
		transform: translateX(0);
	}


	&--will-fade-in {
		opacity: 0;
	}
	&--completed-fade-in {
		opacity: 1;
	}


	&--will-fade-up {
		opacity: 0;
		transform: translateY(100px);
	}
	&--completed-fade-up {
		opacity: 1;
		transform: translateY(0);
	}


	&--will-fade-down {
		opacity: 0;
		transform: translateY(-100px);
	}
	&--completed-fade-down {
		opacity: 1;
		transform: translateY(0);
	}


	&--will-fade-left {
		opacity: 0;
		transform: translateX(100px);
	}
	&--completed-fade-left {
		opacity: 1;
		transform: translateX(0);
	}


	&--will-fade-right {
		opacity: 0;
		transform: translateX(-100px);
	}
	&--completed-fade-right {
		opacity: 1;
		transform: translateX(0);
	}

	&--will-rise {
		overflow: hidden;

		> div {
			position: relative;
			transform: translateY(200%);
			transition: transform 1.5s ease-out;
		}
	}

	&--completed-rise {
		> div {
			transform: translateY(0);
		}
	}

	&--will-reveal {
		transform: translateY(20%);
		opacity: 0;
		//transition: transform 1.5s ease-out;
		//mask-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
		mask-image: linear-gradient(to bottom, rgba(#000, 0) 90%, rgba(#000, 1) 100%);

		/*&::before {
			content: '';
			z-index: 10;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 400%;
			//background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
			mask-image: linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
			transform-origin: center bottom;
			//transition: transform 3.0s ease-in-out;
			transition-delay: inherit;
			transition-duration: inherit;
		}*/
	}

	&--completed-reveal {
		transform: translateY(0);
		opacity: 1;
		mask-image: linear-gradient(to bottom, rgba(#000, 1) 0%, rgba(#000, 1) 100%);

		&::before {
			transform: scale(1, 0);
		}
	}
}
