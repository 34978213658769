@font-face {
    font-family: 'FS Sally';
    src: url('/assets/fonts/FSSallyWeb-Regular.eot') format('eot'),
         url('/assets/fonts/FSSallyWeb-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'FS Sally';
    src: url('/assets/fonts/FSSallyWeb-Italic.eot') format('eot'),
         url('/assets/fonts/FSSallyWeb-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}
