.landing {
	margin-bottom: 90px;

	&__slider,
	&__content {
		transition: transform cubic-bezier(0.5, 0.01, 0.3, 1.01), opacity cubic-bezier(0.5, 0.01, 0.3, 1.01);
		will-change: transform, opacity;
		backface-visibility: hidden;
		transition-duration: 1250ms;
		// opacity: 0;
	}

	&__item {
		margin-bottom: 50px;
		padding-top: 138px;
		background: url('img/separator-dark.svg') no-repeat center -50px;

		@include breakpoint(small only) {
			margin-bottom: 0;
			padding-top: 116px;
			background-image: url('img/separator-dark-small.svg');
			background-position: center top;
		}

		&:nth-child(even) {
			.landing__slider {
				// transform: translateX(100px);

				@include breakpoint(medium) {
					order: 2;
					margin-left: 0;
				}

				// &.landing-alternating-fade-in {
				// 	transform: translateX(0);
				// 	opacity: 1;
				// }
			}

			.landing__content {
				// transform: translateX(-100px);

				@include breakpoint(medium) {
					order: 1;
				}

				@include breakpoint(large) {
					margin-left: 8.33333%;
				}

				// &.landing-alternating-fade-in {
				// 	transform: translateX(0);
				// 	opacity: 1;
				// }

				background-color: #fff;

				@include breakpoint(small only){
					padding: 60px 5% 10px;
				}
			}


		}

		&:nth-child(odd) {
			.landing__slider {
				// transform: translateX(-100px);

				// &.landing-alternating-fade-in {
				// 	transform: translateX(0);
				// 	opacity: 1;
				// }
			}

			.landing__content {
				// transform: translateX(100px);

				// &.landing-alternating-fade-in {
				// 	transform: translateX(0);
				// 	opacity: 1;
				// }
			}

			@include breakpoint(small only){
				margin-bottom: 30px;
			}
		}
	}

	&__slider {
		position: relative;
		min-height: 300px;

		@include breakpoint(large) {
			min-height: 425px;
		}

		&-inner {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		&-image {
			background: no-repeat center center;
			background-size: cover;

			a,
			picture {
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}

			img {
				object-fit: cover;
				width: 100%;
				height: 100%;
			}
		}

		.reform-slider-arrows {
			display: none;
		}
	}

	&__content {
		padding: 60px 5% 50px;
		@include flex-align-center();
		text-align: left;
		background-color: #F0F8FC;

		@include breakpoint(small only) {
			border: none;
		}

		h2 {
			@include h2;
			margin-bottom: 20px;
			text-transform: uppercase;

			a {
				color: inherit;
			}

			@include breakpoint(small only) {
				margin-bottom: 10px;
			}
		}

		h3 {
			@include subheading();
			margin-bottom: 10px;

			a {
				color: inherit;
			}
		}

		p {
			@include body-copy();
		}

		.multiple-buttons {
			padding-top: 37px;
			
			@include breakpoint(small) {
				width: auto;
				margin-left: 0;
				margin-right: 0;
			}
		}

		@include breakpoint(small){
			padding: 50px 5%;
		}
	}

}
