@mixin slider-caption {
	font-family: $primary-font;
	font-size: 50px;
	line-height: 53px;
	font-style: italic;
	@include breakpoint(large) {
		font-size: 77px;
		line-height: 81px;
	}
}


@mixin h1 {
	font-family: $primary-font;
	font-size: 33px;
	line-height: 36px;
	color: get-color('blue');
}


@mixin h2 {
	font-family: $primary-font;
	font-size: 29px;
	line-height: 31px;
	color: get-color('blue');
	text-transform: uppercase;
}


@mixin subheading {
	font-family: $primary-font;
	font-size: 17px;
	line-height: 20px;
	font-style: italic;
	color: get-color('dark-grey');
}


@mixin price {
	font-family: $primary-font;
	font-size: 22px;
	line-height: 25px;
	font-style: italic;
	color: get-color('mid-grey');
}


@mixin burger-menu-sub-nav {
	font-family: $primary-font;
	font-size: 20px;
	line-height: 23px;
	text-transform: uppercase;
	color: get-color('mid-grey');
}


@mixin body-copy {
	font-family: $secondary-font;
	font-size: 13px;
	line-height: 22px;
	color: get-color('dark-grey');
}


@mixin bullet-list {
	font-family: $secondary-font;
	font-weight: 600;
	font-size: 13px;
	line-height: 27px;
	color: get-color('dark-grey');
}


@mixin menu-list {
	font-family: $secondary-font;
	font-weight: 600;
	font-size: 13px;
	line-height: 27px;
	color: get-color('dark-grey');
}


@mixin terms-conditions {
	font-family: $secondary-font;
	font-size: 11px;
	line-height: 17px;
	color: get-color('dark-grey');
}


@mixin smallest {
	font-family: $secondary-font;
	font-size: 11px;
	line-height: 21px;
	text-transform: uppercase;
	color: get-color('dark-grey');
}

p a {
	color: get-color('mid-grey');

	&:hover {
		color: get-color('dark-grey');
	}
}

ul {
  list-style-type: '-  ';
  margin-left: 12px;
}
